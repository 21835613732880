let nodes = document.querySelectorAll('[data-module]')

nodes.forEach(node => { 
    let modules = node.getAttribute('data-module').split(' ');

    modules.forEach(module => { 
    
        import(/* webpackIgnore: true */`./modules/${module}.js`) 
        .then(obj => { 
            obj
        })
    })
})